import Presale from "./pages/Presale";

// define routes
const Routes = [
  {
    path: "/",
    element: <Presale />,
  }
];

export default Routes;
